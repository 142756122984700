<template>
  <div class="AddCustomer">
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      v-if="tableVisible"
      :title="title"
      width="50%"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="集配中心" prop="logistic_business_id">
            <el-select
              placeholder="选择集配中心"
              v-model="formData.logistic_business_id"
              clearable
              filterable
              :disabled="title=='编辑'"
              @change="chang_business_user_ids"
            >
              <el-option
                v-for="item of options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组名" prop="team_name">
            <el-input
              v-model="formData.team_name"
              placeholder="请输入组名"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理城市仓" prop="logistics_ids">
            <el-select
              placeholder="选择管理城市仓"
              v-model="formData.logistics_ids"
              clearable
              filterable
              multiple
            >
              <el-option
                v-for="item of optionslogistics"
                :key="item.id+''"
                :label="item.name"
                :value="item.id+''"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客服账号">
            <el-select
              placeholder="选择客服账号"
              v-model="formData.business_user_ids"
              clearable
              filterable
              multiple
            >
              <el-option
                v-for="item of optionsUserd"
                :key="item.id+''"
                :label="item.nickname"
                :value="item.id+''"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="tableVisible = false">返回</el-button>
        <el-button @click="determine" type="primary" :loading="bntloading"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
    
    <script>
export default {
  name: "AddCustomer",
  props: {},
  data() {
    return {
      tableVisible: false,
      bntloading: false,
      title: "",
      formData: {
        id: "",
        logistic_business_id: "", //	T文本	是	549集配中心id
        team_name: "", //	T文本	是	第一小组分组名
        logistics_ids: [], //	T文本	是	231,284,286城市仓id
        business_user_ids: [], //	T文本	是	231,284,286用户id
      },
      options: [],
      optionsUserd: [],
      optionslogistics: [],
      rules: {
        team_name: [{ required: true, message: "请输入组名", trigger: "blur" }],
        logistic_business_id: [
          { required: true, message: "请选择集配中心", trigger: "change" },
        ],
        logistics_ids: [
          { required: true, message: "请选择管理城市仓", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.getBusinessList();
  },

  methods: {
    hqinfo(e, item) {
      this.title = e;
      this.tableVisible = true;
      this.optionsUserd = [];
      this.optionslogistics = [];
      if (item) {
        this.formData = JSON.parse(JSON.stringify(item));
        this.formData.logistic_business_id = item.business.id;
        if (this.formData.logistic_business_id) {
          this.getservicesteambusinessUserd(this.formData.id);
          this.getservicesteamlogistics(this.formData.id);
        }
      } else {
        Object.assign(this.$data.formData, this.$options.data().formData);
      }
      
    },
    // 获取集配中心数据
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    chang_business_user_ids() {

        this.formData.logistics_ids = [];
        this.formData.business_user_ids = [];
        if (this.formData.logistic_business_id) {
          this.getservicesteambusinessUserd();
          this.getservicesteamlogistics();
        } else {
          this.optionsUserd = [];
          this.optionslogistics = [];
        }
    },

    getservicesteambusinessUserd(e) {
      this.$api.customer
        .servicesteambusinessUserd({
          logistic_business_id: this.formData.logistic_business_id,
          team_id:e?e:''

        })
        .then((res) => {
          console.log(res, "1111111111111111111");
          this.optionsUserd = res.data;
        });
    },
    getservicesteamlogistics(e) {
      this.$api.customer
        .servicesteamlogistics({
          logistic_business_id: this.formData.logistic_business_id,
               team_id:e?e:''
        })
        .then((res) => {
          console.log(res, "2222222222222222222");
          this.optionslogistics = res.data;
        });
    },
    determine() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.bntloading = true;
          if (this.title == "添加") {
            this.tool.debounce(() => {
              this.$api.customer
                .servicesteamadd(this.formData)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  this.bntloading = false;
                  this.tableVisible = false;
                  this.$emit("getList", this.formData);
                })
                .catch((err) => {
                  this.bntloading = false;
                });
            });
          } else {
            this.tool.debounce(() => {
              this.$api.customer
                .servicesteamedit(this.formData)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: "编辑成功",
                  });
                  this.bntloading = false;
                  this.tableVisible = false;
                  this.$emit("getList", this.formData);
                })
                .catch((err) => {
                  this.bntloading = false;
                });
            });
          }
        }
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.AddCustomer {
  .pagination-position {
    margin-top: 20px;
  }
}
</style>
    