var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddCustomer" },
    [
      _vm.tableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: _vm.title,
                width: "50%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.tableVisible = false
                              },
                            },
                          },
                          [_vm._v("返回")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.bntloading },
                            on: { click: _vm.determine },
                          },
                          [_vm._v("确定")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                911678050
              ),
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "集配中心",
                            prop: "logistic_business_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择集配中心",
                                clearable: "",
                                filterable: "",
                                disabled: _vm.title == "编辑",
                              },
                              on: { change: _vm.chang_business_user_ids },
                              model: {
                                value: _vm.formData.logistic_business_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "logistic_business_id",
                                    $$v
                                  )
                                },
                                expression: "formData.logistic_business_id",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "组名", prop: "team_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入组名" },
                            model: {
                              value: _vm.formData.team_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "team_name", $$v)
                              },
                              expression: "formData.team_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "管理城市仓", prop: "logistics_ids" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择管理城市仓",
                                clearable: "",
                                filterable: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.formData.logistics_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "logistics_ids", $$v)
                                },
                                expression: "formData.logistics_ids",
                              },
                            },
                            _vm._l(_vm.optionslogistics, function (item) {
                              return _c("el-option", {
                                key: item.id + "",
                                attrs: {
                                  label: item.name,
                                  value: item.id + "",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客服账号" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择客服账号",
                                clearable: "",
                                filterable: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.formData.business_user_ids,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "business_user_ids",
                                    $$v
                                  )
                                },
                                expression: "formData.business_user_ids",
                              },
                            },
                            _vm._l(_vm.optionsUserd, function (item) {
                              return _c("el-option", {
                                key: item.id + "",
                                attrs: {
                                  label: item.nickname,
                                  value: item.id + "",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }