<template>
  <div class="customerService">
    <div class="title">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="add(false)" type="primary">新增</el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-select
            placeholder="选择客服账号"
            v-model="from.business_user_id"
            clearable
            filterable
          >
            <el-option
              v-for="item of optionsUserd"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            placeholder="选择集配中心"
            v-model="from.logistic_business_id"
            clearable
            filterable
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" align="center" label="集配中心">
          <template slot-scope="scope">
            {{ scope.row?.business?.name }}
          </template>
        </el-table-column>
        <el-table-column prop="team_name" align="center" label="组别">
        </el-table-column>
        <el-table-column
          prop="logistics_names"
          align="center"
          label="管理城市仓"
        >
        </el-table-column>
        <el-table-column
          prop="business_user_names"
          align="center"
          label="关联客服账号"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="add(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <AddCustomer @getList="getList" ref="AddCustomer"></AddCustomer>
  </div>
</template>
    <script>
import { postExportservicesteam } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import AddCustomer from "./modules/AddCustomer.vue";
export default {
  name: "customerService",
  components: { AddCustomer },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        logistic_business_id: "",
        business_user_id: "",
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      options: [],
      optionsUserd: [],
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getBusinessList();
    this.getservicesteambusinessUserd();
  },
  methods: {
    // 获取集配中心数据
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    // 客户用户列表
    getservicesteambusinessUserd() {
      this.$api.customer.servicesteambusinessUserdall({logistic_business_id:''}).then((res) => {
        this.optionsUserd = res.data;
      });
    },
    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportservicesteam(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    add(e) {
      this.$refs.AddCustomer.hqinfo(e ? "编辑" : "添加", e ? e : "");
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.customer
        .servicesteamlist(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
    <style lang="scss">
.customerService {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
    
  