var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerService" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.add(false)
                  },
                },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "seach" },
          [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "选择客服账号",
                      clearable: "",
                      filterable: "",
                    },
                    model: {
                      value: _vm.from.business_user_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "business_user_id", $$v)
                      },
                      expression: "from.business_user_id",
                    },
                  },
                  _vm._l(_vm.optionsUserd, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.nickname, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "选择集配中心",
                      clearable: "",
                      filterable: "",
                    },
                    model: {
                      value: _vm.from.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from, "logistic_business_id", $$v)
                      },
                      expression: "from.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "warning", icon: "el-icon-upload2" },
                on: { click: _vm.exportHandle },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", align: "center", label: "集配中心" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row?.business?.name) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "team_name", align: "center", label: "组别" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics_names",
                  align: "center",
                  label: "管理城市仓",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "business_user_names",
                  align: "center",
                  label: "关联客服账号",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.add(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("AddCustomer", { ref: "AddCustomer", on: { getList: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }